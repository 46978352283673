<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('upload.title')" />

      <b-container class="file-upload-container">
        <b-form-file
          v-model="upload.file"
          class="file-upload-droparea"
          accept="application/zip"
          :placeholder="$t('upload.upload-message')"
          :disabled="upload.busy"
        />
        <b-alert v-if="showFileSizeAlert" show variant="warning" class="mt-3 text-center"
          >{{ $t('upload.file-size-exceeded') }}
        </b-alert>
      </b-container>

      <upload-table
        :cancel-function="cancelUpload"
        :download-function="downloadZip"
        :items="uploadedFiles"
        :table-fields="tableFields"
        :table-controls="tableControls"
        v-on:update:currentPage="updateTablePage"
        class="mt-5"
      />
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import UploadTable from '@/components/UploadTable'

export default {
  name: 'MainUpload',
  components: { UploadTable },
  mixins: [ApiMixin, NotificationMixin],
  data() {
    return {
      upload: {
        file: null,
        busy: false,
        abortController: null
      },
      uploadedFiles: [],
      tableControls: {
        busy: false,
        sort: 'id,desc',
        currentPage: 1,
        perPage: 20,
        totalRows: 0
      },
      showFileSizeAlert: false,
      tableFields: [
        { key: 'time', label: this.$t('upload.time'), sortable: false, width: '150px' },
        { key: 'user', label: this.$t('upload.user'), sortable: false },
        { key: 'progress', label: this.$t('upload.progress'), sortable: false },
        { key: 'status', label: this.$t('upload.status'), sortable: false, width: '160px' },
        { key: 'actions', label: 'Edit', sortable: false, width: '40px' }
      ]
    }
  },
  watch: {
    'upload.file': function () {
      if (this.upload.file === null) {
        return
      }
      let fileSizeInMb = this.upload.file.size / 1024 / 1000
      if (fileSizeInMb > 200) {
        this.showFileSizeAlert = true
      } else {
        this.showFileSizeAlert = false
        this.createUpload()
      }
    }
  },
  mounted() {
    this.getAllUploads()
  },
  methods: {
    updateTablePage(page) {
      this.tableControls.currentPage = page
      this.getAllUploads()
    },
    getAllUploads() {
      let self = this
      self.tableControls.busy = true
      const page = this.$route.query.page ? self.$route.query.page : self.tableControls.currentPage
      const size = self.$route.query.size ? self.$route.query.size : self.tableControls.perPage
      const sort = self.$route.query.sort ? self.$route.query.sort : self.tableControls.sort
      self.getRequest(
        `/uploads?page=${page - 1}&size=${size}&sort=${sort}`,
        new RequestConfig().onSuccess(res => {
          self.tableControls.currentPage = page
          self.uploadedFiles = []
          if (res.data.length === 0) {
            self.tableControls.busy = false
            self.tableControls.totalRows = 0
          } else {
            self.tableControls.totalRows = parseInt(res.headers['x-total-count'])
            res.data.forEach(upload => {
              upload.cancelable = false
              if (upload.status === 'SUCCESSFUL') {
                upload.fileSubmitted = true
                upload.progressbarValues = {
                  upload: 100,
                  import: 0
                }
                upload.uploadError = false
              } else if (upload.status === 'IN_PROGRESS') {
                upload.fileSubmitted = true
                upload.progressbarValues = {
                  upload: 60,
                  import: 40
                }
                upload.uploadError = false
                upload.cancelable = self.isAdmin || self.isTeamMember
              } else {
                upload.fileSubmitted = true
                upload.progressbarValues = {
                  upload: 100,
                  import: 0
                }
                upload.uploadError = true
              }
              self.uploadedFiles.push(upload)
              self.tableControls.busy = false
            })
          }
        })
      )
    },
    downloadZip(uploadId) {
      this.getRequest(
        `uploads/${uploadId}/s3_object/download`,
        new RequestConfig().withAxiosConfig({ responseType: 'blob' }).onSuccess(res => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          // parse filename out of content-disposition header, default upload.zip
          link.download = 'upload.zip'
          const disposition = res.headers['content-disposition']
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
              link.download = matches[1].replace(/['"]/g, '')
            }
          }
          link.click()
          URL.revokeObjectURL(link.href)
          link.remove()
        })
      )
    },
    cancelUpload(rowItem) {
      if (this.upload.abortController !== null) {
        this.upload.abortController.abort()
      }
      this.finishS3ObjectUpload(rowItem, 'CANCELLED')
      this.putRequest(
        `/uploads/${rowItem.id}/status`,
        'CANCELLED',
        new RequestConfig().withAxiosConfig({ headers: { 'Content-Type': 'text/plain' } })
      )
    },
    createUpload() {
      let self = this
      self.upload.busy = true
      self.postRequest(
        '/uploads',
        {},
        new RequestConfig()
          .onSuccess(res => {
            let upload = res.data
            console.debug('Successfully created Upload: ', upload)

            upload.fileSubmitted = true
            upload.progressbarValues = {
              upload: 0,
              import: 100
            }
            upload.progressbarStages = {
              upload: 60,
              import: 40
            }
            upload.uploadError = false
            upload.cancelable = true

            // add newly created upload to the list of uploads
            self.uploadedFiles.unshift(upload)
            self.tableControls.totalRows++

            // timeout for nicer progress bar animation
            setTimeout(() => {
              self.createS3Object(upload.id)
            }, 500)
          })
          .onError(error => {
            console.debug(error)
            this.displayError(this.$t('upload.creation.error-msg'))
            this.resetUploadObject()
          })
      )
    },
    createS3Object(uploadId) {
      let currentIndex = 0
      let self = this
      self.upload.abortController = new AbortController()
      this.postRequest(
        `/uploads/${uploadId}/s3_object`,
        this.upload.file,
        new RequestConfig()
          .withAxiosConfig({
            headers: {
              'Content-Type': 'application/zip'
            },
            signal: self.upload.abortController.signal,
            onUploadProgress: function (progressEvent) {
              let item = self.uploadedFiles[currentIndex]
              if (item.status === 'IN_PROGRESS') {
                item.progressbarValues.upload =
                  Math.round((progressEvent.loaded * 100) / progressEvent.total) * (item.progressbarStages.upload / 100)
                item.progressbarValues.import =
                  100 -
                  Math.round((progressEvent.loaded * 100) / progressEvent.total) * (item.progressbarStages.upload / 100)
              }
            }
          })
          .onSuccess(() => {
            self.displaySuccess(this.$t('upload.s3.success-msg'))
            self.finishS3ObjectUpload(self.uploadedFiles[currentIndex], 'SUCCESSFUL')
          })
          .onError(error => {
            console.log(error)
            if (error.message === 'canceled') {
              this.displaySuccess(this.$t('upload.s3.cancelled-msg'))
              self.finishS3ObjectUpload(self.uploadedFiles[currentIndex], 'CANCELLED')
            } else {
              this.displayError(this.$t('upload.s3.error-msg'))
              self.finishS3ObjectUpload(self.uploadedFiles[currentIndex], 'ERRONEOUS')
              self.uploadedFiles[currentIndex].uploadError = true
            }
          })
      )
    },
    finishS3ObjectUpload(item, status) {
      item.progressbarValues.upload = 100
      item.progressbarValues.import = 0
      item.status = status
      item.cancelable = false
      this.resetUploadObject()
    },
    resetUploadObject() {
      this.upload.file = null
      this.upload.busy = false
      this.upload.abortController = null
    }
  }
}
</script>

<style lang="scss" scoped>
.file-upload-container {
  width: 80%;
}
</style>

<style lang="scss">
.file-upload-droparea {
  height: 250px;
  cursor: pointer;

  .custom-file-label {
    cursor: pointer;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
    border-width: 2px;
    border-radius: 10px;
    white-space: pre;
    text-align: center;

    &::after {
      display: none;
    }

    &:hover {
      background-color: #f8f9fa;
    }
  }
}
</style>

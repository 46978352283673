<template>
  <b-container class="upload-table-container" fluid="lg">
    <b-table
      id="uploadTable"
      class="upload-table"
      small
      head-variant="light"
      hover="hover"
      show-empty
      fixed
      :empty-text="$t('general.table.empty-text')"
      :empty-filtered-text="$t('general.table.empty-text')"
      :fields="tableFields"
      :items="items"
      :busy="tableControls.busy"
      :current-page="tableControls.currentPage"
      :per-page="0"
      stacked="md"
    >
      <template #table-busy>
        <LoadingSpinner />
      </template>
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
      </template>
      <template v-slot:head(actions)="data">
        <div :id="data.label + '-button-container'">
          <b-icon icon="gear" scale="1.2" class="ml-1" />
        </div>
      </template>
      <template v-slot:cell(time)="row">
        {{ row.item.finished ? formatTime(row.item.finished) : formatTime(row.item.created) }}
      </template>
      <template v-slot:cell(status)="row">
        <div v-if="row.item.status === 'IN_PROGRESS'">
          <b-icon icon="circle-fill" variant="primary" font-scale="0.8" class="mr-1" />
          <span>{{ $t('upload.transfer-status-uploading') }}</span>
        </div>
        <div v-if="row.item.status === 'SUCCESSFUL'">
          <b-icon icon="circle-fill" variant="success" font-scale="0.8" class="mr-1" />
          <span>{{ $t('upload.transfer-status-finished') }}</span>
        </div>
        <div v-if="row.item.status === 'ERRONEOUS'">
          <b-icon icon="circle-fill" variant="danger" font-scale="0.8" class="mr-1" />
          <span>{{ $t('upload.transfer-status-erroneous') }}</span>
        </div>
        <div v-if="row.item.status === 'CANCELLED'">
          <b-icon icon="x-circle-fill" variant="secondary" font-scale="0.8" class="mr-1" />
          <span>{{ $t('upload.transfer-status-cancelled') }}</span>
        </div>
      </template>
      <template v-slot:cell(progress)="row">
        <b-progress v-if="row.item.fileSubmitted" :max="100" class="m-1 mt-2 mr-3" height="8px">
          <b-progress-bar
            :value="row.item.progressbarValues.upload"
            :variant="row.item.status === 'CANCELLED' ? 'secondary' : row.item.uploadError ? 'danger' : 'success'"
          />
          <b-progress-bar :value="row.item.progressbarValues.import" :animated="true" />
        </b-progress>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          v-if="row.item.status === 'ERRONEOUS'"
          size="xs"
          variant="outline-dark"
          @click="downloadFunction(row.item.id)"
          v-b-tooltip.right="$t('upload.action.zip-download')"
        >
          <b-icon icon="download" scale="1.2" style="cursor: pointer" />
        </b-button>
        <b-button
          v-if="row.item.cancelable"
          size="xs"
          variant="outline-danger"
          @click="cancelFunction(row.item)"
          v-b-tooltip.right="
            row.item.progressbarValues.upload < 60
              ? $t('upload.action.cancel-upload')
              : $t('upload.action.cancel-import')
          "
        >
          <b-icon icon="x" scale="1.8" />
        </b-button>
      </template>
      <template v-slot:cell(user)="row">
        <span v-if="row.item.user.fullName">{{ row.item.user.fullName }}</span>
        <span v-else>#{{ row.item.user.id }}</span>
      </template>
    </b-table>
    <div class="overflow-auto mt-2 upload-pagination">
      <b-pagination-nav
        :number-of-pages="totalPages"
        :link-gen="paginationLinkGenerator"
        use-router
        @input="updatePage"
      />
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import LoadingSpinner from '@/components/LoadingSpinner'
import { ApiMixin } from '@/mixins/ApiMixin'

export default Vue.extend({
  name: 'UploadTable',
  mixins: [ApiMixin],
  components: {
    LoadingSpinner
  },
  props: {
    tableFields: {
      required: true,
      type: Array
    },
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    downloadFunction: {
      required: true,
      type: Function
    },
    cancelFunction: {
      required: true,
      type: Function
    },
    tableControls: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selectedItem: {}
    }
  },
  computed: {
    totalPages() {
      if (this.tableControls.totalRows === 0) {
        return 1
      }
      return Math.ceil(this.tableControls.totalRows / this.tableControls.perPage)
    }
  },
  methods: {
    formatTime(value) {
      return LocalDateTimeFormatter.formatLocaleDateTime(value)
    },
    paginationLinkGenerator(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    updatePage(event) {
      this.$emit('update:currentPage', event)
    }
  }
})
</script>

<style lang="scss" scoped>
.upload-table-container {
  .upload-table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .upload-pagination {
    display: flex;
    justify-content: center;
  }
}
</style>
